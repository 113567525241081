$line-width: 30px; // Change Me
$line-height: $line-width/12; //... Or Me
$line-spacing: $line-width/3; //... Or Me
$line-color: grey;
$animation-duration: .3s;

@mixin variables {
  height: $line-height;
  width: $line-width;
  background: $line-color;  
  transition: $animation-duration;
}



// input[type='checkbox'] {
//   display: none;
  
//   &:checked ~ .container > .menu {
//     background: transparent;
    
//     &:before {
//       bottom: 0;
//       transform: rotate(45deg)
//     }
    
//     &:after {
//       top: 0;
//       transform: rotate(-45deg)
//     }
//   }
// }

.container.expanded {
  // display: none;
  .menu {
    background: transparent;
    &:before {
      bottom: 0;
      transform: rotate(45deg)
    }
    &:after {
      top: 0;
      transform: rotate(-45deg)
    }
  }
}


.container {
  width: $line-width;
  // height: $line-height + $line-spacing*2;
  height: 40px;
  // position: absolute;
  top: calc(50% - #{$line-height + $line-spacing*2}/2);
  left: calc(50% - #{$line-width/2});
  cursor: pointer;
  padding-top: 15px;
  float: right;
  z-index: 1006;
}

.menu {
  @include variables;
  position: relative;
  transform: translateY($line-spacing);
  
  &:before {
    @include variables;
    position: absolute;
    content: '';
    bottom: $line-spacing;
  }
    
  &:after {
    @include variables;
    position: absolute;
    content: '';
    top: $line-spacing;
  }
}


.menulist {
  width: 100%;
  max-width: 100% !important;
  opacity: 1;
  background: $primary-color;
  top:90px;
  position: absolute;
  color:white;
  padding-top: 1.25rem;
  height: 100%;
  &.menuhide {
    opacity: 0;
  }
  ul {
    list-style: none;
    margin-left: 0;
    li {
      a {
        color: $white;
        font-size: 3.5rem !important;
        line-height: 1.5;
        font-weight: $font-weight-bold;
      }
    }
  }
}

#overlay{
 width:100%;
 height:200vh;
 position:absolute;
 top:0;
 left:0;
 display:none;
 z-index: 2;
}

#close {
  // color:darkgray;
  content: '';
  // height: 100vh;
  // width: 100vw;
}