.home-about {
  .top-section {
    background-color: #ffffff;
  }
}

.top-section {
  background-color: $grey-color;

  .hero {
    padding-bottom: 5rem;
    padding-top: 2rem;
    .hero-info {
      margin-bottom: 10px;
      list-style: none;
      margin-left: 0;
      .hero-item {
        float: left;
        margin-right: 4rem;
        margin-bottom: 1rem;
        &:nth-last-child {
          padding-right: 0;
        }
      }
      .cover-item {
        float: left;
        margin-right: 2rem;
        margin-bottom: 1rem;
        &:nth-last-child {
          padding-right: 0;
        }
      }
    }
      p {
        margin-bottom: 0;
        line-height: 1.5;
        &.dash {
          line-height: 1;
        }
      }
    button {
      background-color: transparent;
      padding: 0;
    }
  }
  header {
    nav {
      padding-top: 1.25rem;
      .menu-items {
        ul {
          list-style: none;
          li {
          }
        }
      }
      h1 {
        height: 50px;
        background: no-repeat url(../img/logo.svg);
        text-indent: -9999%;
        margin-left: -6px;
        font-size: 12px;
      }
    }

  }
  @include breakpoint(medium) { 
    .hero {
      padding-top: 6rem;
      .hero-description {
        max-width: 80%;
      }
    }
    nav {
      padding-top: 1.75rem;
      .menu-items {
        float: right;
        ul {
          li {
            display: inline-block;
            margin-right: 1rem;
            padding-top: 25px;
            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @include breakpoint(large) {
    .hero {
      padding-top: 8rem;
      &.project-hero {
        // padding-top: 3rem;
        // padding-bottom: 2rem;
      }
      .hero-description {
        max-width: 46rem;
        &.top-resume {
          max-width: 42rem !important;
          .row .columns {
            width: auto !important;

          }
        }
        &.widow {
          max-width: 44rem;
        }
      }

    }
  }
}