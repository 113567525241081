.work-grid {
  // .bird {
  //   background-color: red;
  //   height: 25vw;
  // }
  // .meow {
  //   background-color: green;
  //   height: 25vw;
  // }

  .row {
    max-width: 100%;

    a {
      .wrapper {
        height: 100%; 
        overflow: hidden;
        div {
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          height: 60vw;
          overflow: hidden;
          position: relative;
          .overlay {
            height: 101%;
            width: 101%;
            opacity: 0; 
            -webkit-transition: opacity 0.75s ease;
            -moz-transition: opacity 0.75s ease;
            top: 50%;
            position: relative;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            left: 50%;
            position: absolute;
            margin-top: 0;
            text-align: center;
            &.udacity-color {
              background: rgba(65, 26, 69, .5);
              opacity: 1 !important;
            }
            &.cellebrite-color {
              background: rgba(34,51,66,.5);
              opacity: 1 !important;
            }
            span {
              color: white;
              padding-bottom: 1px;
              border-bottom: 3px solid white;
              position: relative;
              top: 45%;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
        }
        .captora {
          background-image: url(../img/captora.jpg);
        }
        .cellebrite {
          background-image: url(../img/cellebrite.jpg);
        }
        .fh-analyzer {
          background-image: url(../img/fh-analyzer.jpg);
        }
        .fh-generator {
          background-image: url(../img/fh-generator.jpg);
        }
        .foundation {
          background-image: url(../img/foundation.jpg);
        }
        .roughcut {
          background-image: url(../img/roughcut.jpg);
        }
        .udacity {
          background-image: url(../img/udacity.jpg);
        }

      }
    }

    // @include breakpoint(small-only) { 

    // }

    @include breakpoint(medium) { 
      a {
        .wrapper div {
          height: 30vw;
          .overlay {
            height: 101%;
          }
        }
      }
    }
    @include breakpoint(large) { 

      a {
        // &:hover .wrapper div {
        //   transform: scale(1.04);
        //   transition: transform .8s ease;
      // }
        .wrapper div {
          height: 28vw;
          // transition: transform .8s ease;
          .overlay {
            // background: rgba(0, 0, 0, 0.5);
            height: 101%;
            width: 101%;
            opacity: 0; 
            -webkit-transition: opacity 0.75s ease;
            -moz-transition: opacity 0.75s ease;
            top: 50%;
            position: relative;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            left: 50%;
            position: absolute;
            margin-top: 0;
            text-align: center;
            &.captora-color {
              background: rgba(35, 40, 51, .75);
            }
            &.cellebrite-color {
              background: rgba(34,51,66,.5);
              opacity: 1 !important;
            }
            &.foundation-color {
              background: rgba(7, 78, 104, 0.75);
            }
            &.fh-ltblue-color {
              background: rgba(2,145,227,.75);
            }
            &.fh-drkblue-color {
              background: rgba(18,64,100,.75);
            }
            &.roughcut-color {
              background: rgba(205, 142, 97, .75);
            }

            &.udacity-color {
              background: rgba(65, 26, 69, .5);
              opacity: 1 !important;
            }

            &:hover {
              opacity: 1;
            }
            span {
              color: white;
              padding-bottom: 1px;
              border-bottom: 3px solid white;
              position: relative;
              top: 45%;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    @include breakpoint(xlarge) { 
      .columns {
        // width: 33.3333333%;
        a {
          .wrapper div {
            height: 27vw;
            .overlay {
              // background: rgba(0, 0, 0, 0.5);
              height: 101%;
              width: 101%;
              opacity: 0; 
              -webkit-transition: opacity 0.75s ease;
              -moz-transition: opacity 0.75s ease;
              top: 50%;
              position: relative;
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              left: 50%;
              position: absolute;
              margin-top: 0;
              text-align: center;
              &:hover {
                opacity: 1;
              }
              span {
                color: white;
                padding-bottom: 1px;
                border-bottom: 3px solid white;
                position: relative;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }

}