
.project-hero.about {
  h4 {
    margin-bottom: 0.7rem;
  }
}

.project-content.about {
  .small-collapse {
    max-width: 100%;
  }
  .column-block {
    margin: 0;
    padding: 0;
  }  

  h5 {
    font-family: DINProMedium;
    font-size: .8rem;
    color: #b9b9b9;
    margin-bottom: 0.5rem;
  }

 
  p {
    margin-bottom: 0;      
    span.subtext {
      color: #b9b9b9;
      &.location {
        padding-left: 17px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          margin-top: 5px;
          width: 10px;
          height: 25px;
          background: url(../img/locationpin.svg) no-repeat left top;
        }
      }
    }
    span.maintext {
      font-family: DINProMedium;
    }
  }
  .resume-item {
    margin-bottom: 3rem;
  }
  .project-text {
    padding-bottom: 4rem;
  }

  @include breakpoint(medium) { 
    h5 {
      // margin-bottom: 1rem;
    }
  }
  @include breakpoint(large) { 
    p {
      padding-right: 0px;
    }
    .project-text {
      padding-bottom: 8rem;
    }
  }
}