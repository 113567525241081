@charset 'utf-8';
@font-face {
  font-family: 'DINProRegular';
  src: url('../fonts/dinpro-regular-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/dinpro-regular-webfont.woff') format('woff'), 
       url('../fonts/dinpro-regular-webfont.ttf')  format('truetype'),
       url('../fonts/dinpro-regular-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'DINProMedium';
  src: url('../fonts/dinpro-medium-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/dinpro-medium-webfont.woff') format('woff'), 
       url('../fonts/dinpro-medium-webfont.ttf')  format('truetype'),
       url('../fonts/dinpro-medium-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'DINProLight';
  src: url('../fonts/dinpro-light-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/dinpro-light-webfont.woff') format('woff'), 
       url('../fonts/dinpro-light-webfont.ttf')  format('truetype'),
       url('../fonts/dinpro-light-webfont.svg#svgFontName') format('svg');
}
$font-family-sans-serif: "DINProRegular", "Lato", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$font-family-bold: "DINProMedium", "Lato", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

$font-weight-normal: normal;
$font-weight-bold: bold;

 
@import 'settings';
@import 'foundation';
@import 'motion-ui';



// global layout
@import "menu-overlay";
@import "footer";


// homepage
@import "top-section";
@import "earth";
@import "work-grid";

// project pages
@import "foundation-styles";
@import "project-content";
@import "custom-slider";
@import "resume";
@import "about";
@import "coverletter";




@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;



.hangingquote {
  text-indent: -0.4em; 
}

.homepage {
  @include breakpoint(large) { 
    margin-bottom: 135px;
  }
}


.grey-captora-background {
  background-color: #d2d9e4;
}

.snow-background {
  background-color: #F1F6F8;
}

.grey-background {
  background-color: $grey-color;
}

.project-page.contact-page {
  height: 100vh;
}


a {
  &.close {
    border-bottom: 1px solid $primary-color;
    transition: padding-bottom .2s;
  }
  &:hover {
    // border-bottom-color: scale-color($primary-color, $lightness: -14%);
    border-bottom: 2px solid;
    transition: padding-bottom .2s;
    padding-bottom: 1px;
  }
}

h1, h2 {
  font-family: $font-family-bold;
  margin-left: -4px;
  // font-size: 3rem;
}

h3 {
  font-family: $font-family-bold;
}
.primary-color {
  color: $primary-color;
}

img {
  vertical-align: top;
}


.ness-icon {

}
.ness {
  cursor: url(../img/ness.png), pointer;
  height: 50%;
  &:hover {
    cursor: url(../img/ness.png), pointer;
  }
}

.sword-icon {

}
.sword {
  cursor: url(../img/sword.png), pointer;
  height: 100%;
  &:hover {
    cursor: url(../img/sword.png), pointer;
  }
}

.ragnell-icon {

}
.ragnell {
  cursor: url(../img/ragnell.png), pointer;
  height: 100%;
  &:hover {
    cursor: url(../img/ragnell.png), pointer;
  }
}

@include breakpoint(small only) { 
  .column, .columns {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .row .row {
    margin: 0 -1.75rem;
  }
}
@include breakpoint(medium only) { 
  .column, .columns {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .row .row {
    margin: 0 -1.75rem;
  }
}
@include breakpoint(large) { 
  .row {
    max-width: 75rem;
  }
}

.divider-box {
  width: 0px;
  background-color: #dedede;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin: auto;
  z-index: 1;
  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -50%;
    content: '';
    width: 0;
    height: 0;
    border-top: solid 12px #dedede;
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
    transform: translateX(-50%);
  }
  &.project-page:after {
    border-top: solid 12px $grey-color;
  }
}


p.widow {
  margin-right: 25px;
}


// hr {
//  max-width: 60em;
//  margin: auto;
// }


//  header {
//    max-width: none !important;
//    .header {
//      background-color: $primary-color;
//    }
//    .navigation-columns {
//      padding: 0;
//    }
//    .inner-wrap {
//      // padding-left: 0.9375rem;
//      // padding-right: 0.9375rem;
//    }
//    .tab-bar {
//      background: $primary-color;
//       // padding: 30px 0.9375rem;
//      padding: 2rem 2rem 0 2rem;

//      // max-width: 60em;
//      // margin: 10px auto;
//      ul {
//        list-style: none;
//        float: right;
//        li {
//          display: table-cell;
//          a {
//            padding: 0 0.5rem;
//          }
//        }
//      }
//       a {
//        color: $white;
//        // float: right;
//        line-height: 1.4;
//       }
//      .right-small {
//        border: none;
//        float: right;
//        position: relative;
//        // margin: 30px 0.9375rem 0 0;

//      }
//    }
//  }
// // }



// header { 
//  .top-bar {
//    height: 50px;
//    padding: 40px 0.9375rem;
//    background: $primary-color;
//    .title-area, .title-area .name {
//      height: 50px;
//    }
//  }

//  .portfolio-link {
//    background: transparent !important;
//    & a {
//      background: transparent !important;
//    }
//  }
//  h1 {
//    height: 50px;
//    background: no-repeat url(../img/logo.svg);
//    margin: 40px 0 40px 0;
//    text-indent: -900%;
//  }


//  .main-section {
//    .row {
//      max-width: 60em;
//      margin: 0 auto;
//    }
//    .description {
//      padding-top: 50px;
//      padding-bottom: 75px;
//      background: $primary-color;

//      @include breakpoint(small only) { 
//        h2 {
//          font-size: 1.8em;
//        }
//        p {
//          font-size: 1.2em;
//          letter-spacing: 0.02em;
//        }
//      }


//      h2 {
//        color: $white;
//        font-weight: $font-weight-bold;
//      }
//      p {
//        color: $white;
//        font-weight: $font-weight-normal;
//      }
//    }
//    .form, .contact {
//      padding: 40px 0;
//    }
//  }
// }


