.project-content.resume {
  h5 {
    font-family: DINProMedium;
    font-size: .8rem;
    color: #b9b9b9;
    margin-bottom: .5rem;
  }

 
  p {
    margin-bottom: 0;      
    span.subtext {
      color: #b9b9b9;
      &.location {
        padding-left: 17px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          margin-top: 5px;
          width: 10px;
          height: 25px;
          background: url(../img/locationpin.svg) no-repeat left top;
        }
      }
    }
    span.maintext {
      font-family: DINProMedium;
    }
  }
  .resume-item {
    margin-bottom: 3rem;
  }
  .project-text {
    padding-bottom: 4rem;
  }
  @include breakpoint(large) { 
    p {
      padding-right: 0px;
    }
    .project-text {
      padding-bottom: 8rem;
    }
  }
}