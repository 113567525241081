@include breakpoint(large) { 
  .sky {
    div {
      border-radius: 50% 50%;
      background-repeat: no-repeat;
      position: absolute;
      height: 75px;
      width: 75px;
    }
    .sunrise {
      top: 50%;
      left: 58%;
      background-image: url(../img/sun.svg);
    }
    .sun {
      top: 34%;
      left: 63%;
      background-image: url(../img/sun.svg);
    }
    .noon {
      top: 13%;
      left: 69%;
      background-image: url(../img/sun.svg);
    }
    .sunset {
      top: 40%;
      left: 82%;
      background-image: url(../img/sun.svg);
    }
    .evening {
      top: 27%;
      left: 75%;
      background-image: url(../img/moon.svg);
    }
    .moon {
      top: 16%;
      left: 68%;
      background-image: url(../img/moon.svg);
    }
    .dawn {
      top: 37%;
      left: 63%;
      background-image: url(../img/moon.svg);
    }
  }
}

.homepage .mountain {
  background-image: url(../img/small-mountain.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  // transform: scale(1.005);

  @include breakpoint(medium) { 
    background-image: url(../img/medium-mountain.svg);
  }
  @include breakpoint(large) { 
    background-image: url(../img/large-mountain.svg);
  }
  @include breakpoint(xlarge) { 
    background-image: url(../img/xlarge-mountain.svg);
  }

}