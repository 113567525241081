.icon-slider {
  transition: all 300ms ease;
  .slick-slide {
    transition: all 300ms ease;
    // padding: 20px;
  }
  .slick-center {
    transition: all 300ms ease;
  }
}


.skills-slider, .refind-slider {
  img {
    padding: 0 20px;
  }
}

@include breakpoint(large) { 
  .icon-slider {
    transition: all 300ms ease;
    .slick-slide {
      transition: all 300ms ease;
      padding: 20px;
      opacity: .5;
    }
    .slick-center {
      transform: scale(1.2);
      transition: all 300ms ease;
      opacity: 1;
    }
  }
}