.foundation-container {
	.header-container {
	  padding: 15px 30px 10px 30px;
	  background: #0b4f69;
	  border-top-left-radius: 5px;
	  border-top-right-radius: 5px;
	  h3 {
	  	color: white;
	  }
  }
  .info-list {
    font-size: .95rem;
    background: #fafafa;
    list-style: none;
    margin: 0 0 20px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    li {
    	padding: 20px 30px;
    	&:nth-child(even) {
    		background: #f5f5f5;
    	}
    }
    .code-container {
	    margin: 10px 0px;
	    padding: 5px;
	    overflow-x: auto;
	    line-height: 1.3rem;
	    font-weight: normal;
    }
    pre {
    	code {
		    border-color: #dfdfdf;
		    border-style: solid;
		    border-width: 1px;
		    display: block;
		    padding: 0.5em;
    	}
    	.tag {
    		color: #000080;
  		}
  		.attribute {
    		color: #008080;
  		}
  		.value {
  	    color: #d14;
  		}
    }
  }
}