
.project-content {
  .testimonial {
    margin-bottom: 5rem !important;
    img {
      border-radius: 50%;
    }
    p {
      margin-left: 25%;
    }
    .second-testimonial {
      margin-top: 2rem;
    }
  }
  .image-credit {
    font-size: 0.8rem;
    margin: 15px 0 0 5px;
    // color: #b9b9b9;
  }

  img {
    width: 100%;
  }

  .foundation-background {
    background-color: #074e68;
    h3, p {
      color: $grey-color;
    }
    &.foundation-navigation {
      padding-top: 4rem;
      .foundation-navigation-image {
        padding-right: 0;
      }
    }
  }
  .roughcut-background {
    background-color: #c3996b;
  }
  .roughcut-block {
    .row .column-block {
      margin-bottom: 0;
      padding: 0;
    }
  }
  .roughcut-auto {
    max-width: 100%;
    .columns {
      padding: 0;
    }
  }

  .grey-background {
    background-color: $grey-color;
    position: relative;
  }
  .captoragrey-background {
    background-color: #d2d9e6;
  }
  .white-background {
    background-color: #ffffff;
  }
  .project-image {
    max-width: 100%;
  }
  .project-text, .caption-text {
    // padding-top: 3rem;
    // padding-bottom: 5rem;
    padding-top: 6rem;
    padding-bottom: 8rem;
    &.codependemo {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .audit-grid {
    max-width: 85rem;
    .audit-item {
      h4, p { 
        padding: 0 2%;
      }
      // &:last-child {
      //   padding-right: 0;
      // }
      img {
        box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        width: 99%;
      }
      p {
        margin-bottom: 3rem;
      }
      h4 {
        margin-top: 1.25rem;
      }
    }

  }
  .caption-image {
    padding-right: 0;
    padding-left: 0;
  }
  .project-slider {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .text-nobottom {
    padding-bottom: 0rem;
    padding-top: 6rem;
  }
  .text-before {
    padding-bottom: 4rem;
    padding-top: 6rem;
  }
  .project-svg {
    // padding-top: 3rem;
    padding-bottom: 5rem;
    .the-svg {
      padding-top: 3rem;
    }
  }
  .photo-background {
    position: relative;
    .project-text {
      padding-top: 8rem;
      padding-bottom: 14rem;
    }
    &:before {
    content: " ";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    z-index: -1;
    background-size: cover;
    }
  }
  .conclusion {
    .subheader {
      font-family: DINProLight;
      font-size: .8rem;
    }
  }
  .project-pagination {
    padding-bottom: 3rem;

    a {
      color: #a7a7a7;
      text-align: center;
    }
    @include breakpoint(small only) {
      a { 
        display: block;
        &:nth-child(1) {
          padding-bottom: 20px;
        }
      }
    }
    @include breakpoint(medium) {
      a {
        border-bottom: 1px solid #a7a7a7;
      }
    }


  }
  @include breakpoint(medium) { 
    .testimonial {
      h3 {
        margin-top: 2rem;
      }
    }
    .project-image .project-text {
      padding-top: 16rem;
      padding-bottom: 0rem;
      p {
        padding-right: 150px;
      }
    }
    .text-before {
      padding-top: 8rem;
    }
    .project-text, {
      padding-top: 8rem;
      padding-bottom: 10rem;

    }
    .caption-text {
      padding-top: 8rem;
    }
    .project-slider {
      padding-top: 6rem;
      padding-bottom: 8rem;
    }
    .project-svg {
      // padding-top: 4rem;
    }
    .photo-background {
      .project-text {
        padding-top: 12rem;
        padding-bottom: 20rem;
      }
    }

    .conclusion {
      .project-text {
        padding-top: 6rem;
        padding-bottom: 9rem;
      }
      .project-pagination {
        a {
          text-align: left;
          transition: padding-bottom .2s;
          padding-bottom: 0px;
          &:hover {
            border-bottom: 2px solid #a7a7a7;
            transition: padding-bottom .2s;
            padding-bottom: 1px;
          }
          &.large-right {
            float: right;
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            // transition: border-bottom .8s ease;
            transition: padding-bottom .2s;

            // transition: color .8s ease;

            &:hover {
              // color: scale-color($primary-color, $lightness: -14%);
              // border-bottom-color: scale-color($primary-color, $lightness: -14%);
              border-bottom: 2px solid $primary-color;
              transition: padding-bottom .2s;
              padding-bottom: 1px;
            }
          }
        }
      }
    }

  }
  @include breakpoint(medium) { 
    .testimonial .second-testimonial .row .columns {
      position: relative !important;
    }
    .audit-grid {
      padding-top: 6rem;
      padding-bottom: 8rem;
    }
  }
  @include breakpoint(large) { 
    display: block;
    margin-bottom: 135px;
    .audit-grid {
      padding-top: 6rem;
      padding-bottom: 8rem;
    }
    .testimonial {
      .second-testimonial {
        margin-top: 0;
      }
      h3 {
        margin-top: 1.5rem;
      }
    }
    .caption-text {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .project-caption {
      padding-top: 5%;

    }
    .text-before {
      p {
        padding-right: 300px;
      }
    }
    .project-text {
      padding-bottom: 18rem;
    }
    .project-text, .text-before, .text-nobottom {
      p {
        // width: 65%;
        padding-right: 300px;
        &.widow {
          margin-right: 15px;
        }
        &.two-column {
          columns: 2;
          // width: 100%;
          padding-right: 0;
        }
      }
    }
    .project-slider {
      padding-top: 8rem;
    }
    .project-image {
      position: relative;

      .overlay {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        opacity: 0; 
        -webkit-transition: opacity 0.75s ease;
        -moz-transition: opacity 0.75s ease;
        top: 50%;
        position: relative;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        margin-top: 0;
        text-align: center;
        &:hover {
          opacity: 1;
        }
        span {
          color: white;
          padding-bottom: 1px;
          border-bottom: 3px solid white;
          position: relative;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}