footer {
  // background-color: #222;
  &.resume-footer {
    background-color: $grey-color;
  }
  background-color: $grey-color;
  height: 135px;
  bottom: 0;
  width: 100%;
  z-index: -2;
  svg {
      // fill: $white;
      fill: #a7a7a7;
      margin: 55px auto;
      height: 30px;
      display: block;
      &.emailsvg {
        height: 25px;
        margin-top: 58px;
      }
      path {
        -webkit-transition: fill 0.5s ease;
        -moz-transition: fill 0.5s ease;
      }
  }

  @include breakpoint(medium) {
    .row {
      max-width: 35rem;
    }
  }
  @include breakpoint(large) {
    position: fixed;
    a:hover {
      svg path {
        fill: $primary-color;
      }
    }
  }
}