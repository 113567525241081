.project-content.coverletter {
  h5 {
    font-family: DINProMedium;
    font-size: .8rem;
    color: #b9b9b9;
    margin-bottom: .5rem;
  }
  span.signature p {
    margin: 0;
  }
  img {
    margin-bottom: 1rem;
  }

  .project-text {
    padding-bottom: 4rem;
  }
  @include breakpoint(large) { 
    .project-text { 
      p {
        padding-right: 0px;
        font-size: 1.05rem;
        margin-right: -40px;
      }
      .smoler p {
        margin-right: -44px;
      }
    }
    .project-text {
      padding-bottom: 8rem;
    }
  }
}